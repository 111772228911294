@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/montserrat-v24-latin_cyrillic-regular.eot');
  src: local(''), url('../assets/fonts/montserrat-v24-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/montserrat-v24-latin_cyrillic-regular.woff2') format('woff2'),
    url('../assets/fonts/montserrat-v24-latin_cyrillic-regular.woff') format('woff'),
    url('../assets/fonts/montserrat-v24-latin_cyrillic-regular.ttf') format('truetype'),
    url('../assets/fonts/montserrat-v24-latin_cyrillic-regular.svg#Montserrat') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/montserrat-v24-latin_cyrillic-600.eot');
  src: local(''), url('../assets/fonts/montserrat-v24-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/montserrat-v24-latin_cyrillic-600.woff2') format('woff2'),
    url('../assets/fonts/montserrat-v24-latin_cyrillic-600.woff') format('woff'),
    url('../assets/fonts/montserrat-v24-latin_cyrillic-600.ttf') format('truetype'),
    url('../assets/fonts/montserrat-v24-latin_cyrillic-600.svg#Montserrat') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/montserrat-v24-latin_cyrillic-800.eot');
  src: local(''), url('../assets/fonts/montserrat-v24-latin_cyrillic-800.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/montserrat-v24-latin_cyrillic-800.woff2') format('woff2'),
    url('../assets/fonts/montserrat-v24-latin_cyrillic-800.woff') format('woff'),
    url('../assets/fonts/montserrat-v24-latin_cyrillic-800.ttf') format('truetype'),
    url('../assets/fonts/montserrat-v24-latin_cyrillic-800.svg#Montserrat') format('svg');
}

// @import "./_reset.scss";

body {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  color: #000;
  overflow-x: hidden;
}

*[x-apple-data-detectors],
.x-gmail-data-detectors,
.x-gmail-data-detectors *,
.aBn {
  border-bottom: 0 !important;
  cursor: default !important;
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}

ol {
  list-style-type: decimal;
  padding-left: 24px;
}

ol li {
  margin-bottom: '8px';
  display: 'list-item';
}
